import React, { useState, useEffect } from "react";
import fetchToken from "../Auth/FetchToken";
import Toast, { showErrorToast } from "../Components/Toast";
import axios from "axios";
import "../style/projectList.css";
import { useNavigate } from "react-router-dom";

const ProjectList = ({ setIsLoading }) => {
  const [jira_project_list, setJiraProject_list] = useState([]);
  const [connected_project, setConnected_projects] = useState({});
  const navigate = useNavigate();
  const fetchProjectList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/projects/list`,
        {
          headers: {
            accept: "application/json",
            Authorization: "Bearer " + fetchToken().access_token,
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        showErrorToast("Something went wrong! Please try again.");
        return false;
      }
    } catch (e) {
      if (e && e.response && e.response.staus === 401) {
        showErrorToast("Session Expired! Please login again.");
      } else {
        showErrorToast("Something went wrong! Please try again.");
      }
      console.error("Error fetching project list: ", e);
      return false;
    }
  };
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const fetch_response = await fetchProjectList();
      if (fetch_response) {
        setJiraProject_list(fetch_response.jira_project_list || []);
        setConnected_projects(fetch_response.connected_projects || {});
      }
    };
    fetchData();
    setIsLoading(false);
  }, [setIsLoading]);
  return (
    <>
      <Toast />
      <div className="project-list">
        <div className="card">
          <div className="card-body">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Index</th>
                  <th scope="col">Jira Project</th>
                  <th scope="col">Bizzdesign Collection</th>
                  <th scope="col">Logs</th>
                </tr>
              </thead>
              <tbody>
                {jira_project_list.map((project, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td
                        className="hover-text-underline"
                        key={index + 1}
                        onClick={() =>
                          navigate(`/project-details/${project.key}`)
                        }
                      >
                        {project.key || ""}
                      </td>
                      <td>
                        {connected_project[project.key] ? (
                          <span className="badge-success">
                            {connected_project[project.key]["collection_name"]}
                          </span>
                        ) : (
                          <span className="badge-danger">Not Connected</span>
                        )}
                      </td>
                      <td>
                        <>
                          {connected_project[project.key] ? (
                            <a
                              // style={{ backgroundColor: "#128c7e" }}
                              href={`/log-details/${project.key}`}
                              role="button"
                            >
                              <span className="badge-success">show logs</span>
                            </a>
                          ) : (
                            <span className="badge-danger">Not Connected</span>
                          )}
                        </>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProjectList;
