import React, { createContext, useState, useEffect } from "react";
import CircularPageLoading from "../Components/Loading";
import axios from "axios";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = sessionStorage.getItem("access_token");
    setIsAuthenticated(!!token);
    setLoading(false);
  }, []);

  const refreshAccessToken = async () => {
    const refresh_token = sessionStorage.getItem("refresh_token");
    const refresh_token_url = `${process.env.REACT_APP_BACKEND_URL}/login/refresh-token?refresh_token=${refresh_token}`;
    try {
      const response = await axios.post(
        refresh_token_url,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        sessionStorage.setItem("access_token", response.data.access_token);
        setIsAuthenticated(true);
      } else {
        console.log("No access token in response");
      }
    } catch (e) {
      console.log("Error refreshing token", e);
    }
  };

  useEffect(() => {
    const checkAndRefreshToken = async () => {
      const token = sessionStorage.getItem("access_token");
      if (token) {
        const tokenData = JSON.parse(atob(token.split(".")[1]));
        const exp = tokenData.exp * 1000;
        const now = new Date().getTime();
        console.log("exp", exp - now);
        if (exp - now < 5 * 60 * 1000) {
          await refreshAccessToken();
        }
      }
    };

    checkAndRefreshToken();

    // const interval = setInterval(checkAndRefreshToken, 1.5 * 60 * 60 * 1000);
    const interval = setInterval(checkAndRefreshToken, 1 * 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return <CircularPageLoading />;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
