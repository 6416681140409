import React, { useState } from "react";
import { PulseLoader } from "react-spinners";
import axios from "axios";
import fetchToken from "../Auth/FetchToken";
import Toast, { showErrorToast, showSuccessToast } from "../Components/Toast";
import "../style/changePass.css";

const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [userinput, setUserInput] = useState({
    currentPass: "",
    newPass: "",
    confirmPass: "",
  });
  const [error, setError] = useState({
    currentPass: "",
    newPass: "",
    confirmPass: "",
    formError: "",
  });
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setError({ ...error, [name]: "" });
    setUserInput({ ...userinput, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (userinput.newPass !== userinput.confirmPass) {
      setError({ confirmPass: "Please enter same password as new password" });
      setIsLoading(false);
      return;
    }
    if (userinput.currentPass === userinput.newPass) {
      setError({
        newPass: "New password should not be same as current password",
      });
      setIsLoading(false);
      return;
    }
    const updatePass_url = `${process.env.REACT_APP_BACKEND_URL}/user/update-password`;
    try {
      const response = await axios.put(
        updatePass_url,
        {
          current_password: userinput.currentPass,
          new_password: userinput.newPass,
        },
        {
          headers: {
            accept: "application/json",
            Authorization: "Bearer " + fetchToken().access_token,
          },
        }
      );
      if (response.status === 200) {
        showSuccessToast("Password updated successfully");
        setUserInput({
          currentPass: "",
          newPass: "",
          confirmPass: "",
        });
        setError({
          currentPass: "",
          newPass: "",
          confirmPass: "",
          formError: "",
        });
      }
    } catch (e) {
      if (e && e.response) {
        if (e.response.status === 401) {
          setError({
            formError: "Oops! Invalid user",
          });
          showErrorToast("Oops! Invalid user");
        } else if (e.response.status === 400) {
          setError({
            currentPass:
              "Oops! Invalid password, Please enter correct password",
          });
        } else {
          setError({
            formError: "Oops! something went wrong. Please try again later",
          });
          showErrorToast("Oops! something went wrong. Please try again later");
        }
      } else {
        setError({
          formError: "Oops! something went wrong. Please try again later",
        });
        showErrorToast("Oops! something went wrong. Please try again later");
      }
    }
    setIsLoading(false);
  };
  return (
    <>
      <Toast />
      <div className="changePass">
        <div className="changePass-card-wrapper">
          <div className="changePass-card">
            <form className="changePass-form" onSubmit={handleSubmit}>
              <h2>Change Password</h2>
              <div className={`form-group ${error.currentPass ? "error" : ""}`}>
                <label htmlFor="username">Current Password</label>
                <input
                  type="password"
                  id="currentPass"
                  name="currentPass"
                  placeholder="Current Password"
                  value={userinput.currentPass}
                  onChange={handleFormChange}
                  required
                />
                {error.currentPass && (
                  <span className="error-line">{error.currentPass}</span>
                )}
              </div>
              <div className={`form-group ${error.newPass ? "error" : ""}`}>
                <label htmlFor="password">New Password</label>
                <div className="input-group">
                  {/* this passwors should be strong and should 8char long */}
                  <input
                    type={showPassword ? "text" : "password"}
                    id="newPass"
                    placeholder="New Password"
                    name="newPass"
                    value={userinput.newPass}
                    onChange={handleFormChange}
                    minLength={8}
                    required
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={`fas fa-eye${showPassword ? "-slash" : ""}`}
                      ></i>
                    </span>
                  </div>
                </div>
                {error.newPass && (
                  <span className="error-line">{error.newPass}</span>
                )}
              </div>
              <div className={`form-group ${error.confirmPass ? "error" : ""}`}>
                <label htmlFor="username">Confirm Password</label>
                <input
                  type="password"
                  id="confirmPass"
                  name="confirmPass"
                  placeholder="Confirm Password"
                  value={userinput.confirmPass}
                  onChange={handleFormChange}
                  required
                />
                {error.confirmPass && (
                  <span className="error-line">{error.confirmPass}</span>
                )}
              </div>
              {error.formError && (
                <span className="error-line">{error.formError}</span>
              )}
              <div className="changePass-btn-wrapper">
                <button
                  className="changePass-btn"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <PulseLoader color="#FFFFFF" />
                  ) : (
                    "Confirm Changes"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
