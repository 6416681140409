import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CircularPageLoading from "../Components/Loading";
import Toast, { showErrorToast, showSuccessToast } from "../Components/Toast";
import fetchToken from "../Auth/FetchToken";
import axios from "axios";
import archimateOptions from "../utils/archimateOptions";
import "../style/projectDetails.css";

const ProjectDetails = () => {
  const { projectKey } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [connectionStatus, setConnectionStatus] = useState(false);
  const [detailsAvailable, setDetailsAvailable] = useState(false);
  const navigate = useNavigate();

  const [projectData, setProjectData] = useState({
    status: "",
    jira_project_id: "",
    bizz_repository_name: "",
    collection_name: "",
    issue_details: [],
    bizz_repository_list: [],
  });
  const fetchProjectDetails = async (projectKey) => {
    try {
      const fetchProject_url = `${process.env.REACT_APP_BACKEND_URL}/get-data/collection-data?project_key=${projectKey}`;
      const response = await axios.get(fetchProject_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + fetchToken().access_token,
        },
      });
      if (response.status === 200) {
        if (response.data["response_status"] === "success") {
          setProjectData(response.data["data"]);
          setDetailsAvailable(true);
          setConnectionStatus(true);
        } else {
          setProjectData((prevData) => ({
            ...prevData,
            issue_details: response.data["data"]["issue_details"] || [],
            jira_project_id: response.data["data"]["jira_project_id"] || "",
            bizz_repository_list:
              response.data["data"]["bizz_repository_list"] || [],
          }));
        }
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      showErrorToast("Oops! Something went wrong.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProjectDetails(projectKey);
  }, [projectKey]);

  const handleInputChange = (field, value) => {
    setProjectData({ ...projectData, [field]: value });
  };

  const handleIssueInputChange = (index, value) => {
    const updatedIssues = [...projectData.issue_details];
    if (value !== "--------------------------------") {
      updatedIssues[index].issue_archimate = value;
      setProjectData({ ...projectData, issue_details: updatedIssues });
    }
  };

  const handleConnect = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const modifiedData = {
      status: "active",
      jira_project_id: projectData.jira_project_id || "",
      project_key: projectKey || "",
      bizz_repository_name: projectData.bizz_repository_name || "",
      collection_name: projectData.collection_name || "",
      issue_details:
        projectData.issue_details.map((issue) => ({
          jira_issue_id: issue.jira_issue_id,
          issue_type: issue.issue_type,
          issue_name: issue.issue_name,
          issue_archimate: issue.issue_archimate || "ArchiMate:IMWorkpackage",
        })) || [],
    };

    try {
      const add_data_url = `${process.env.REACT_APP_BACKEND_URL}/add-data/project-data`;

      const response = await axios.post(add_data_url, modifiedData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + fetchToken().access_token,
        },
      });
      if (response.status === 200) {
        showSuccessToast("Project Connected Successfully!");
        setConnectionStatus(true);
        setDetailsAvailable(true);
      } else {
        showErrorToast("Oops! Something went wrong.");
      }
    } catch (e) {
      console.error("error:", e);
      showErrorToast("Oops! Something went wrong.");
    }
    setIsLoading(false);
  };

  const handleDelete = async () => {
    if (
      window.confirm("Delete project from connector and Bizzdesign Horizzon?")
    ) {
      setIsLoading(true);
      try {
        const delete_url = `${process.env.REACT_APP_BACKEND_URL}/delete-data/full-project?project_key=${projectKey}`;
        const response = await axios.delete(delete_url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + fetchToken().access_token,
          },
        });
        if (response.status === 200) {
          showSuccessToast("Project Deleted Successfully!");
          setConnectionStatus(false);
          setDetailsAvailable(false);
          setProjectData({
            status: "",
            jira_project_id: "",
            bizz_repository_name: "",
            collection_name: "",
            issue_details: [],
            bizz_repository_list: [],
          });
          await fetchProjectDetails(projectKey);
        } else {
          showErrorToast("Oops! Something went wrong.");
        }
      } catch (e) {
        console.error("error:", e);
        showErrorToast("Oops! Something went wrong.");
      }
      setIsLoading(false);
    }
  };
  return (
    <>
      <Toast />
      {isLoading ? (
        <CircularPageLoading />
      ) : (
        <div className="projectlist-container mt-5">
          <form onSubmit={handleConnect}>
            <div className="projectlist-header mb-4">
              <h2 className="font-weight-300">{projectKey}</h2>
              <div className="projectlist-buttons">
                <button
                  type="submit"
                  className={`projectlist-sync-button ${
                    connectionStatus ? "projectlist-disabled" : ""
                  }`}
                  title={connectionStatus ? "Connected" : "Connect Now"}
                  disabled={connectionStatus}
                >
                  {connectionStatus ? "Connected" : "Connect Now"}
                </button>
                {connectionStatus && (
                  <>
                    <button
                      type="button"
                      className="projectlist-log-button"
                      title="Show logs"
                      onClick={() => {
                        navigate(`/log-details/${projectKey}`);
                      }}
                    >
                      show logs
                    </button>
                    <button
                      type="button"
                      className="projectlist-log-button"
                      title="Delete Project"
                      onClick={handleDelete}
                    >
                      <i
                        className="fa fa-trash"
                        aria-hidden="true"
                        style={{ color: "#DC4C64" }}
                      ></i>
                    </button>
                  </>
                )}
              </div>
            </div>
            <hr className="projectlist-divider" />
            <div>
              <div className="projectlist-flex">
                <div className="half-div">
                  <div className="form-group">
                    <label htmlFor="repositoryName">
                      {`Repository ID (e.g. 1367)`}
                    </label>
                    {detailsAvailable ? (
                      <input
                        type="text"
                        id="repositoryName"
                        value={projectData.bizz_repository_name}
                        onChange={(e) =>
                          handleInputChange(
                            "bizz_repository_name",
                            e.target.value
                          )
                        }
                        disabled
                        required
                      />
                    ) : (
                      <select
                        id="repositoryName"
                        value={projectData.bizz_repository_name}
                        onChange={(e) =>
                          handleInputChange(
                            "bizz_repository_name",
                            e.target.value
                          )
                        }
                        required
                      >
                        <option value="">Select Repository</option>
                        {projectData.bizz_repository_list.map(
                          (repository, index) => (
                            <option
                              key={repository.repository_id || index}
                              value={repository.repository_id}
                            >
                              {`${repository.repository_id} - ${repository.repository_name}`}
                            </option>
                          )
                        )}
                      </select>
                    )}
                  </div>
                </div>
                <div className="half-div">
                  <div className="form-group">
                    <label htmlFor="collectionName">
                      {`Collection Name (no spaces, starting with letter)`}
                    </label>
                    <input
                      type="text"
                      id="collectionName"
                      placeholder="collection"
                      value={projectData.collection_name}
                      onChange={(e) =>
                        handleInputChange("collection_name", e.target.value)
                      }
                      disabled={detailsAvailable}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="projectlist-issue-types py-4">
                <h2 className="font-weight-300">
                  Map Jira issue types with ArchiMate
                </h2>

                <div className="card">
                  <div className="card-body">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Index</th>
                          <th scope="col">Issue Type</th>
                          <th scope="col">Archimate element type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projectData.issue_details.length > 0 ? (
                          projectData.issue_details.map((issue, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <span className="badge-success">
                                  {issue.issue_type}
                                </span>
                              </td>
                              <td>
                                <div className="form-group">
                                  <label htmlFor={`archimatevalue${index}`}>
                                    Archimate
                                  </label>
                                  <select
                                    id={`archimatevalue${index}`}
                                    value={issue.issue_archimate || ""}
                                    onChange={(e) =>
                                      handleIssueInputChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                    disabled={detailsAvailable}
                                    required
                                  >
                                    {archimateOptions.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="4" style={{ textAlign: "center" }}>
                              Issue type is not available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default ProjectDetails;
