import React, { useState, useEffect } from "react";
import axios from "axios";
import fetchToken from "../Auth/FetchToken";
import DataForm from "./DataForm";
import CircularPageLoading from "../Components/Loading";
import { showErrorToast } from "../Components/Toast";
import ProjectList from "./ProjectList";

const Home = () => {
  const [inputExists, setInputExists] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const checkIfInputExists = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/credential-data/check-data-exists`,
        {
          headers: {
            accept: "application/json",
            Authorization: "Bearer " + fetchToken().access_token,
          },
        }
      );
      if (response.status === 200) {
        return response.data.exists || false;
      } else {
        showErrorToast("Oops! Something went wrong.");
        return false;
      }
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        showErrorToast("Oops! Login and try again.");
      }
      showErrorToast("Oops! Something went wrong.");

      console.error("Error checking data:", e);
      return false;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const exists = await checkIfInputExists();
      setInputExists(exists);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return isLoading ? (
    <>
      <CircularPageLoading />
    </>
  ) : (
    <>
      {inputExists ? (
        <>
          <ProjectList setIsLoading={setIsLoading} />
        </>
      ) : (
        <>
          <DataForm
            setInputExists={setInputExists}
            setIsLoading={setIsLoading}
          />
        </>
      )}
    </>
  );
};

export default Home;
