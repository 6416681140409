import React, { useState } from "react";
import axios from "axios";
import fetchToken from "../Auth/FetchToken";
import Toast, { showErrorToast, showSuccessToast } from "../Components/Toast";
import "../style/changeCredential.css";

const DataForm = ({ setIsLoading, setInputExists }) => {
  const [bizzData, setBizzData] = useState({
    bizz_host: "",
    client_id: "",
    client_secret: "",
  });
  const [dataCheck, setDataCheck] = useState({
    jira_data: "",
    bizz_data: "",
  });
  const [jiraData, setJiraData] = useState({
    jira_host: "",
    jira_username: "",
    atlassian_api_token: "",
  });
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const combined_data = {
        jira_data: jiraData,
        bizz_data: bizzData,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/credential-data/save-combined-data`,
        combined_data,
        {
          headers: {
            accept: "application/json",
            Authorization: "Bearer " + fetchToken().access_token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        if (
          response &&
          response.data &&
          response.data.msg === "Connection failed"
        ) {
          showErrorToast("Please check your credentials.");

          setDataCheck({
            jira_data: response.data.jira_connection_status || false,
            bizz_data: response.data.bizz_connection_status || false,
          });
          setIsLoading(false);
          return;
        }
        showSuccessToast("Form submitted successfully.");
        setInputExists(true);
      }
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        showErrorToast("Oops! Login and try again.");
      } else if (e && e.response && e.response.status === 409) {
        showErrorToast("Oops! Data already exists.");
      } else {
        showErrorToast("Oops! Something went wrong.");
      }
      console.error("Error saving data:", e);
    }
    setIsLoading(false);
  };

  const handle_jira_input = (event) => {
    const { name, value } = event.target;
    setJiraData({ ...jiraData, [name]: value });
  };

  const handle_bizz_input = (event) => {
    const { name, value } = event.target;
    setBizzData({ ...bizzData, [name]: value });
  };

  const CredentialValidator = ({ platform, dataCheckVar }) => (
    <>
      <a
        target="_blank"
        rel="noreferrer"
        href={
          platform === "Atlassian"
            ? `https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/`
            : `https://help.bizzdesign.com/articles/#!horizzon-help/creating-a-named-api-client-for-the-bizzdesign-open-api`
        }
      >
        <i
          className="fa-solid fa-circle-info"
          title={`Click here to access ${platform} Support Portal.`}
        ></i>
      </a>
      {dataCheck[dataCheckVar] === true && (
        <i
          className="fa-solid fa-check"
          style={{ color: "#14a44d", fontSize: "28px" }}
        ></i>
      )}
      {dataCheck[dataCheckVar] === false && (
        <>
          <i
            className="fa-solid fa-xmark"
            style={{ color: "#dc4c64", fontSize: "28px" }}
          ></i>
          <p style={{ fontSize: "16px", color: "#dc4c64" }}>
            Invalid credentials. Please verify the credentials and try again.
          </p>
        </>
      )}
    </>
  );

  return (
    <>
      <Toast />
      <div className="credential-page">
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleFormSubmit}>
              <h4 className="font-weight-300">
                <div className="info">
                  Atlassian Jira
                  <CredentialValidator
                    platform="Atlassian"
                    dataCheckVar="jira_data"
                  />
                </div>
              </h4>
              <div className="form-group">
                <label className="form-label">Jira Host</label>
                <input
                  type="text"
                  className="form-control"
                  name="jira_host"
                  placeholder="e.g. yourcompanyname.atlassian.net"
                  onChange={handle_jira_input}
                  value={jiraData.jira_host}
                  required
                />
              </div>
              <div className="form-group">
                <label className="form-label">Jira Username</label>
                <input
                  type="text"
                  className="form-control"
                  name="jira_username"
                  placeholder="e.g. name@example.com"
                  onChange={handle_jira_input}
                  value={jiraData.jira_username}
                  required
                />
              </div>
              <div className="form-group">
                <label className="form-label">Atlassian API Token</label>
                <input
                  type="text"
                  className="form-control"
                  name="atlassian_api_token"
                  placeholder="*************"
                  onChange={handle_jira_input}
                  value={jiraData.atlassian_api_token}
                  required
                />
              </div>

              <h4 className="font-weight-300">
                <div className="info">
                  Bizzdesign Horizzon
                  <CredentialValidator
                    platform="Bizzdesign Horizzon"
                    dataCheckVar="bizz_data"
                  />
                </div>
              </h4>
              <div className="form-group">
                <label className="form-label">Bizzdesign Host</label>
                <input
                  type="text"
                  className="form-control"
                  name="bizz_host"
                  placeholder="e.g. yourcompanyname.horizzon.cloud"
                  onChange={handle_bizz_input}
                  value={bizzData.bizz_host}
                  required
                />
              </div>
              <div className="form-group">
                <label className="form-label">Client ID</label>
                <input
                  type="text"
                  className="form-control"
                  name="client_id"
                  placeholder="e.g. api_rw"
                  onChange={handle_bizz_input}
                  value={bizzData.client_id}
                  required
                />
              </div>
              <div className="form-group">
                <label className="form-label">Client Secret</label>
                <input
                  type="text"
                  className="form-control"
                  name="client_secret"
                  placeholder="*************"
                  onChange={handle_bizz_input}
                  value={bizzData.client_secret}
                  required
                />
              </div>

              <div className="update-btn-wrapper">
                <button className="update-btn" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataForm;
