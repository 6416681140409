import React from "react";
import { useNavigate } from "react-router-dom";
import "../style/navbar.css";

const Navbar = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <nav className="site-header">
        <div className="site-identity">
          <a href="/">
            <img
              src={`${process.env.PUBLIC_URL}/images/favicon.svg`}
              alt="Logo"
              className="logo-image"
              style={{ width: "55px" }}
            />
          </a>
        </div>
        <nav className="site-navigation">
          <ul className="nav">
            <li>
              <a href="/">Projects</a>
            </li>
            <li>
              <a href="/change-credential">Change Credentials</a>
            </li>
            <li>
              <a href="/profile">Change Password</a>
            </li>
            <li>
              <a href="/" onClick={handleLogout}>
                Logout
              </a>
            </li>
          </ul>
        </nav>
      </nav>
    </>
  );
};

export default Navbar;
