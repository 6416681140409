import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CircularPageLoading from "../Components/Loading";
import Toast, { showErrorToast } from "../Components/Toast";
import fetchToken from "../Auth/FetchToken";
import "../style/logDetails.css";

const LogDetails = () => {
  const { projectKey } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [logList, setLogList] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const get_log_list = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/get-logs?project_key=${projectKey}`,
          {
            headers: {
              accept: "application/json",
              Authorization: "Bearer " + fetchToken().access_token,
            },
          }
        );
        if (response.status === 200) {
          return response.data;
        } else {
          showErrorToast("Something went wrong! Please try again.");
          return false;
        }
      } catch (e) {
        if (e && e.response && e.response.status === 401) {
          showErrorToast("Session Expired! Please login again.");
        } else if (e && e.response && e.response.status === 404) {
          showErrorToast("No logs found for this project");
          return {
            log_list: [],
          };
        } else {
          showErrorToast("Something went wrong! Please try again.");
        }
        console.error("Error fetching log list: ", e);
        return false;
      }
    };

    get_log_list().then((data) => {
      if (data && data.log_list) {
        const sortedLogs = data.log_list.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setLogList(sortedLogs);
      }
      setIsLoading(false);
    });
  }, [projectKey]);

  const handleCSVDownload = async () => {
    // want to download csv file of tabled data
    const csvData = logList.map((log) => {
      return {
        "Created At": new Date(log.created_at).toLocaleString(),
        Component: log.log_area,
        "Log Detail": log.log_detail,
        "Log Status": log.log_status,
      };
    });

    const csvRows = [];
    const headers = Object.keys(csvData[0]);
    csvRows.push(headers.join(","));
    for (const row of csvData) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }
    const csv = csvRows.join("\n");

    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", `${projectKey}_log_details.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <Toast />
      {isLoading ? (
        <CircularPageLoading />
      ) : (
        <div className="log-container">
          <div className="projectlist-header mb-4">
            <h2 className="font-weight-300">{projectKey}</h2>
            <div className=".projectlist-buttons">
              {logList.length > 0 && (
                <button
                  type="button"
                  className="dowload-csv-btn"
                  onClick={handleCSVDownload}
                  data-mdb-ripple-init
                >
                  <i className="fas fa-download"></i> Download CSV
                </button>
              )}
            </div>
          </div>
          <hr className="projectlist-divider" />
          <div className="card overflow-hidden">
            <div className="card-body" style={{ padding: 0 }}>
              <table className="table">
                <thead>
                  <tr
                    className="bg-whatsapp"
                    style={{
                      fontSize: "1rem",
                      color: "white",
                    }}
                  >
                    <th className="text-center" scope="col">
                      Created At
                    </th>
                    <th className="text-start" scope="col">
                      Component
                    </th>
                    <th className="text-start" scope="col">
                      Log Detail
                    </th>
                    <th className="text-center" scope="col">
                      Log Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {logList.length > 0 ? (
                    logList.map((log) => (
                      <tr key={log.log_id}>
                        <td className="text-center">
                          {new Date(log.created_at).toLocaleString()}
                        </td>
                        <td>{log.log_area}</td>
                        <td>{log.log_detail}</td>
                        <td className="text-center">
                          {log.log_status === "success" ? (
                            <span className="badge-success"> success </span>
                          ) : (
                            <span className="badge-danger"> failed </span>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          No logs found
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LogDetails;
