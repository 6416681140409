const archimateOptions = [
  "ArchiMate:IMWorkpackage",
  "ArchiMate:IMProgram",
  "ArchiMate:IMPlateau",
  "ArchiMate:IMImplementationEvent",
  "ArchiMate:IMGap",
  "ArchiMate:IMDeliverable",
  "ArchiMate:MotivationValue",
  "ArchiMate:MotivationUseCase",
  "ArchiMate:MotivationStakeholder",
  "ArchiMate:MotivationRequirement",
  "ArchiMate:MotivationRecommendation",
  "ArchiMate:MotivationPrinciple",
  "ArchiMate:MotivationOutcome",
  "ArchiMate:MotivationOptions",
  "ArchiMate:MotivationMetric",
  "ArchiMate:MotivationMeaning",
  "ArchiMate:MotivationGuideline",
  "ArchiMate:MotivationGoal",
  "ArchiMate:MotivationElement",
  "ArchiMate:MotivationDriver",
  "ArchiMate:MotivationConstraint",
  "ArchiMate:MotivationAssessment",
  "--------------------------------",
  "ArchiMate:TechnologySystemSoftware",
  "ArchiMate:TechnologyService",
  "ArchiMate:TechnologyProcess",
  "ArchiMate:TechnologyPath",
  "ArchiMate:TechnologyNode",
  "ArchiMate:TechnologyInterface",
  "ArchiMate:TechnologyInteraction",
  "ArchiMate:TechnologyFunction",
  "ArchiMate:TechnologyEvent",
  "ArchiMate:TechnologyDevice",
  "ArchiMate:TechnologyCommunicationNetwork",
  "ArchiMate:TechnologyCollaboration",
  "ArchiMate:TechnologyArtifact",
  "ArchiMate:StrategyValueStream",
  "ArchiMate:StrategyResource",
  "ArchiMate:StrategyCourseOfAction",
  "ArchiMate:StrategyCapability",
  "ArchiMate:RSVulnerability",
  "ArchiMate:RSThreatEvent",
  "ArchiMate:RSThreatAgent",
  "ArchiMate:RSSecurityPrinciple",
  "ArchiMate:RSSecurityDomain",
  "ArchiMate:RSRisk",
  "ArchiMate:RSLossEvent",
  "ArchiMate:RSControlObjective",
  "ArchiMate:RSControlMeasure",
  "ArchiMate:PhysicalMaterial",
  "ArchiMate:PhysicalFacility",
  "ArchiMate:PhysicalEquipment",
  "ArchiMate:PhysicalDistributionNetwork",
  "ArchiMate:CompositeLocation",
  "ArchiMate:CompositeGrouping",
  "ArchiMate:BusinessService",
  "ArchiMate:BusinessRole",
  "ArchiMate:BusinessRepresentation",
  "ArchiMate:BusinessProduct",
  "ArchiMate:BusinessProcess",
  "ArchiMate:BusinessObject",
  "ArchiMate:BusinessInterface",
  "ArchiMate:BusinessInteraction",
  "ArchiMate:BusinessFunction",
  "ArchiMate:BusinessEvent",
  "ArchiMate:BusinessContract",
  "ArchiMate:BusinessCollaboration",
  "ArchiMate:BusinessActor",
  "ArchiMate:BusinessActivity",
  "ArchiMate:ApplicationService",
  "ArchiMate:ApplicationProcess",
  "ArchiMate:ApplicationInterface",
  "ArchiMate:ApplicationInteraction",
  "ArchiMate:ApplicationFunction",
  "ArchiMate:ApplicationEvent",
  "ArchiMate:ApplicationDataObject",
  "ArchiMate:ApplicationComponent",
  "ArchiMate:ApplicationCollaboration",
];

export default archimateOptions;
