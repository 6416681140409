import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../style/LoginForm.css";
import { PulseLoader } from "react-spinners";
import { AuthContext } from "./AuthProvider";
import Toast, { showErrorToast, showSuccessToast } from "../Components/Toast";

const Login = () => {
  const [userinput, setUserInput] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const login_url = `${process.env.REACT_APP_BACKEND_URL}/login/access-token`;
      const login_response = await axios.request({
        method: "POST",
        url: login_url,
        data: new URLSearchParams(userinput), // Correctly format form data
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (login_response.status === 200) {
        sessionStorage.setItem(
          "access_token",
          login_response.data.access_token
        );
        sessionStorage.setItem(
          "refresh_token",
          login_response.data.refresh_token
        );
        showSuccessToast("Logged in successfully");
        setIsAuthenticated(true);

        navigate("/");
      } else {
        console.log("Error logging in", login_response);
        showErrorToast("Oops! Error while logging in.");
      }
    } catch (error) {
      showErrorToast("Oops! something went wrong.");
      console.error("Error logging in", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setUserInput({ ...userinput, [name]: value });
  };

  return (
    <>
      <Toast />
      <div className="login-container">
        <div className="left">
          <img
            rel="preload"
            src={`${process.env.PUBLIC_URL}/images/login-background.svg`}
            alt="login"
          ></img>
        </div>
        <div className="right-side">
          <div className="text-wrapper">
            <h3>Welcome to Bizzdesign-JIRA Connector</h3>
            <p>
              Seamlessly integrate and manage your projects with real-time data
              synchronization.
            </p>
          </div>
          <form className="login-form" onSubmit={handleSubmit}>
            <h2>Login</h2>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Username"
                value={userinput.username}
                onChange={handleFormChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <div className="input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Password"
                  name="password"
                  value={userinput.password}
                  onChange={handleFormChange}
                  required
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <i
                      className={`fas fa-eye${showPassword ? "-slash" : ""}`}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
            <button className="btn" type="submit" disabled={isLoading}>
              {isLoading ? <PulseLoader color="#FFFFFF" /> : "Login"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
