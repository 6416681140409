import React from "react";
import { MoonLoader } from "react-spinners";

const CircularPageLoading = () => {
  return (
    <div className="loading">
      <MoonLoader color="#38B6FF" />
    </div>
  );
};
export default CircularPageLoading;
