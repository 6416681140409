import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthProvider";
import CircularPageLoading from "../Components/Loading";

const RequireAuth = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  console.log("This is is Authenticated", isAuthenticated);

  // set MoonLoder in center
  if (isAuthenticated === undefined) {
    return <CircularPageLoading />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default RequireAuth;
