import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./screens/Home";
import Login from "./Auth/Login";
import AuthProvider from "./Auth/AuthProvider";
import RequireAuth from "./Auth/RequireAuth";
import ProjectDetails from "./screens/Project-details";
import LogDetails from "./screens/LogDetails";
import ChangeCredential from "./screens/changeCredential";
import ChangePassword from "./Auth/changePassword";
import Navbar from "./Components/Navbar";

const Layout = ({ children }) => {
  const location = useLocation();

  const noNavBarRoutes = ["/login"];

  const notshowNavBar = !noNavBarRoutes.includes(location.pathname);

  return (
    <>
      {notshowNavBar && <Navbar />}
      {children}
    </>
  );
};

const App = () => {
  return (
    <>
      <AuthProvider>
        <Layout />
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/project-details/:projectKey"
            element={
              <RequireAuth>
                <ProjectDetails />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/log-details/:projectKey"
            element={
              <RequireAuth>
                <LogDetails />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/change-credential"
            element={
              <RequireAuth>
                <ChangeCredential />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <RequireAuth>
                <ChangePassword />
              </RequireAuth>
            }
          />
          <Route path="*" element={<Home />} />
        </Routes>
      </AuthProvider>
    </>
  );
};

export default App;
