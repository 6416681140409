import "./index.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const container = document.getElementById("root");
const check = createRoot(container);
check.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
