const fetchToken = () => {
  const access_token = sessionStorage.getItem("access_token");
  const refresh_token = sessionStorage.getItem("refresh_token");

  if (!access_token || !refresh_token) {
    throw new Error("No tokens found");
  }

  return { access_token, refresh_token };
};

export default fetchToken;
