import React, { useEffect, useState } from "react";
import fetchToken from "../Auth/FetchToken";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Toast, { showErrorToast, showSuccessToast } from "../Components/Toast";
import CircularPageLoading from "../Components/Loading";

const ChangeCredential = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [showPassword, setShowPassword] = useState({
    atlassian_api_token: false,
    client_secret: false,
  });
  const [dataCheck, setDataCheck] = useState({
    jira_data: "",
    bizz_data: "",
  });
  const [bizzData, setBizzData] = useState({
    bizz_host: "",
    client_id: "",
    client_secret: "",
  });
  const [jiraData, setJiraData] = useState({
    jira_host: "",
    jira_username: "",
    atlassian_api_token: "",
  });

  useEffect(() => {
    const getExistedData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/credential-data/check-data-exists`,
          {
            headers: {
              accept: "application/json",
              Authorization: "Bearer " + fetchToken().access_token,
            },
          }
        );
        if (response.status === 200) {
          return response.data || false;
        } else {
          showErrorToast("Oops! Something went wrong.");
          return false;
        }
      } catch (e) {
        if (e && e.response && e.response.status === 401) {
          showErrorToast("Oops! Login and try again.");
        }
        showErrorToast("Oops! Something went wrong.");
        console.error("Error checking data:", e);
        return false;
      }
    };
    const fetchData = async () => {
      const existedData = await getExistedData();
      if (existedData) {
        if (existedData.exists === false) {
          showSuccessToast("Please fill the Credential Data form first.");
          navigate("/");
        } else {
          setBizzData(existedData.bizz_data || {});
          setJiraData(existedData.jira_data || {});
        }
      } else {
        sessionStorage.clear();
        showErrorToast("Oops! Login and try again.");
        navigate("/login");
      }
      setIsLoading(false);
    };
    fetchData();
  }, [navigate]);

  const handle_jira_input = (event) => {
    const { name, value } = event.target;
    setJiraData({ ...jiraData, [name]: value });
  };

  const handle_bizz_input = (event) => {
    const { name, value } = event.target;
    setBizzData({ ...bizzData, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/credential-data/update-combined-data`,
        {
          bizz_data: bizzData,
          jira_data: jiraData,
        },
        {
          headers: {
            accept: "application/json",
            Authorization: "Bearer " + fetchToken().access_token,
          },
        }
      );
      if (response.status === 200) {
        if (
          response &&
          response.data &&
          response.data.msg === "Connection failed"
        ) {
          showErrorToast("Please check your credentials.");
          setDataCheck({
            jira_data: response.data["jira_connection_status"] || false,
            bizz_data: response.data["bizz_connection_status"] || false,
          });
          setIsLoading(false);
          return;
        }
        showSuccessToast("Data updated successfully.");
        navigate("/dashboard");
      } else {
        showErrorToast("Oops! Something went wrong.");
      }
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        showErrorToast("Oops! Login and try again.");
      }
      showErrorToast("Oops! Something went wrong.");
      console.error("Error updating data:", e);
    }
    setIsLoading(false);
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword({ ...showPassword, [field]: !showPassword[field] });
  };

  const ShowPasswordIcon = ({ field }) => (
    <div className="input-group-append">
      <span
        className="input-group-text"
        onClick={() => togglePasswordVisibility(field)}
      >
        <i className={`fas fa-eye${showPassword[field] ? "-slash" : ""}`}></i>
      </span>
    </div>
  );

  const CredentialValidator = ({ platform, dataCheckVar }) => (
    <>
      <a
        target="_blank"
        rel="noreferrer"
        href={
          platform === "Atlassian"
            ? `https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/`
            : `https://help.bizzdesign.com/articles/#!horizzon-help/creating-a-named-api-client-for-the-bizzdesign-open-api`
        }
      >
        <i
          className="fa-solid fa-circle-info"
          title={`Click here to access ${platform} Support Portal.`}
        ></i>
      </a>
      {dataCheck[dataCheckVar] === true && (
        <i
          className="fa-solid fa-check"
          style={{ color: "#14a44d", fontSize: "28px" }}
        ></i>
      )}
      {dataCheck[dataCheckVar] === false && (
        <>
          <i
            className="fa-solid fa-xmark"
            style={{ color: "#dc4c64", fontSize: "28px" }}
          ></i>
          <p style={{ fontSize: "16px", color: "#dc4c64" }}>
            Invalid credentials. Please verify the credentials and try again.
          </p>
        </>
      )}
    </>
  );

  return (
    <>
      <Toast />
      {isLoading ? (
        <CircularPageLoading />
      ) : (
        <div className="credential-page">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleFormSubmit}>
                <h4 className="font-weight-300">
                  <div className="info">
                    Atlassian Jira
                    <CredentialValidator
                      platform={"Atlassian"}
                      dataCheckVar={"jira_data"}
                    />
                  </div>
                </h4>
                <div className="form-group">
                  <label className="form-label">Jira Host</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g. yourcompanyname.atlassian.net"
                      name="jira_host"
                      value={jiraData.jira_host || ""}
                      onChange={handle_jira_input}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">Jira Username</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g. name@example.com"
                      name="jira_username"
                      value={jiraData.jira_username || ""}
                      onChange={handle_jira_input}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">Atlassian API Token</label>
                  <div className="input-group">
                    <input
                      type={
                        showPassword.atlassian_api_token ? "text" : "password"
                      }
                      className="form-control"
                      placeholder="*************"
                      name="atlassian_api_token"
                      value={jiraData.atlassian_api_token || ""}
                      onChange={handle_jira_input}
                      required
                    />
                    <ShowPasswordIcon field="atlassian_api_token" />
                  </div>
                </div>
                <h4 className="font-weight-300">
                  <div className="info">
                    Bizzdesign Horizzon
                    <CredentialValidator
                      platform={"Bizzdesign Horizzon"}
                      dataCheckVar={"bizz_data"}
                    />
                  </div>
                </h4>
                <div className="form-group">
                  <label className="form-label">Bizzdesign Host</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g. yourcompanyname.horizzon.cloud"
                      name="bizz_host"
                      value={bizzData.bizz_host || ""}
                      onChange={handle_bizz_input}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">Client ID</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g. api_rw"
                      name="client_id"
                      value={bizzData.client_id || ""}
                      onChange={handle_bizz_input}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">Client Secret</label>
                  <div className="input-group">
                    <input
                      type={showPassword.client_secret ? "text" : "password"}
                      className="form-control"
                      placeholder="*************"
                      name="client_secret"
                      value={bizzData.client_secret || ""}
                      onChange={handle_bizz_input}
                      required
                    />
                    <ShowPasswordIcon field="client_secret" />
                  </div>
                </div>

                <div className="update-btn-wrapper">
                  <button className="update-btn" type="submit">
                    Update Data
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ChangeCredential;
